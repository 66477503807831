import JsonSchema from 'jsonschema'
import capitalize from 'lodash/capitalize'
import { PRODUCT_DATABASE_SEARCHED } from 'constants/activation_points/product_database'
import { sendSegmentTrackEvent, safeSegmentCall } from 'services/segment'
import { getProductForSegment } from 'helpers/segment'
import { STANDARD_TIERS, OVERSIZE_TIERS } from 'constants/filters'
import { removeNullAndEmpty } from 'helpers/objects'
import { getESFiltersValues } from 'helpers/categories'

export const validateDatabaseFilters = filters => {
  const validator = new JsonSchema.Validator()

  // Only numbers can be input into the form, or through an error
  // Allow whitespaces though
  const floatRegex = /^\s*(?:[1-9]\d*|0)?(?:\.\d+)?\s*$/g

  const rangeSchema = {
    type: ['object'],
    required: false,
    properties: {
      min: {
        required: false,
        anyOf: [
          { type: 'string', pattern: floatRegex },
          { type: 'null' },
          { type: 'number' }
        ]
      },
      max: {
        required: false,
        anyOf: [
          { type: 'string', pattern: floatRegex },
          { type: 'null' },
          { type: 'number' }
        ]
      }
    }
  }

  const SCHEMA = {
    type: 'object',
    properties: {
      estRevenue: rangeSchema,
      estimatedSales: rangeSchema,
      listingQualityScore: rangeSchema,
      net: rangeSchema,
      nSellers: rangeSchema,
      price: rangeSchema,
      rank: rangeSchema,
      rating: rangeSchema,
      nReviews: rangeSchema,
      weight: rangeSchema
    }
  }

  const result = validator.validate(filters, SCHEMA)

  if (!result.valid) {
    result.message = buildValidationErrors(result.errors)
  }

  return result
}

const buildValidationErrors = errors => {
  const formattedErrors = errors.map(error => {
    const propertyArr = error.property.split('.')
    return `"${error.instance}" is not a valid value for ${getFilterName(
      propertyArr[1],
      propertyArr[2]
    )}`
  })

  return `${formattedErrors.join(', ')}.`
}

const getFilterName = (property, minOrMax) => {
  switch (property) {
    case 'estimatedSales':
      return `Est. Sales (${minOrMax})`
    case 'estRevenue':
      return `Est. Revenue (${minOrMax})`
    case 'nSellers':
      return `Number of Sellers (${minOrMax})`
    case 'listingQualityScore':
      return `Listing Quality Score (${minOrMax})`
    default:
      return `${capitalize(property)} (${minOrMax})`
  }
}

export const calcRevenue = ({ estRevenue, price, sales }) => {
  if (parseFloat(estRevenue)) return estRevenue

  if (parseFloat(price) && sales)
    return Math.round(parseFloat(price) * sales * 100) / 100

  return 0
}

export const areFiltersEmpty = (filters = {}) => {
  let isEmpty = true
  const filtersToValidate = [
    'price',
    'net',
    'rank',
    'estimatedSales',
    'estRevenue',
    'nReviews',
    'rating',
    'weight',
    'nSellers',
    'listingQualityScore'
  ]
  filtersToValidate.forEach(filter => {
    const values = filters[filter]
    if (values.min || values.min === 0 || values.max || values.max === 0) {
      isEmpty = false
    }
  })
  return isEmpty
}

const generateDateForSegment = dateAsInt => {
  if (!dateAsInt) {
    return null
  }
  let date = null
  try {
    date = new Date(dateAsInt).toISOString()
  } catch {
    // Ignore and return null
  }
  return date
}

const isIncludedInTier = (selected, tiers) => {
  return selected.some(item => tiers.includes(item))
}

export const trackDatabaseSearchOnSegment = ({
  filters,
  excludeTopBrands,
  marketplace,
  appType
}) => {
  safeSegmentCall(() => {
    const tiersSelected = filters.tier.valuesArray
    const productTiers = []
    if (isIncludedInTier(tiersSelected, STANDARD_TIERS)) {
      productTiers.push('Standard')
    }
    if (isIncludedInTier(tiersSelected, OVERSIZE_TIERS)) {
      productTiers.push('Oversize')
    }
    const getLqsValue = value =>
      typeof value === 'string' ? Math.round(value / 10) : null

    sendSegmentTrackEvent(PRODUCT_DATABASE_SEARCHED, {
      marketplace,
      filters: [
        {
          type: 'Product Tier',
          value: productTiers.join('; ')
        },
        {
          type: 'Seller Type',
          value: filters.sellerType.valuesArray.join('; ')
        },
        {
          type: 'Categories',
          value: filters.calculatedCategory.valuesArray.join('; ')
        }
      ],
      minPrice: filters.price.min,
      maxPrice: filters.price.max,
      minNet: filters.net.min,
      maxNet: filters.net.max,
      minRank: filters.rank.min,
      maxRank: filters.rank.max,
      minSales: filters.estimatedSales.min,
      maxSales: filters.estimatedSales.max,
      minRev: filters.estRevenue.min,
      maxRev: filters.estRevenue.max,
      minReviews: filters.nReviews.min,
      maxReviews: filters.nReviews.max,
      minRating: filters.rating.min,
      maxRating: filters.rating.max,
      minWeight: filters.weight.min,
      maxWeight: filters.weight.max,
      minSellers: filters.nSellers.min,
      maxSellers: filters.nSellers.max,
      minLqs: getLqsValue(filters.listingQualityScore.min),
      maxLqs: getLqsValue(filters.listingQualityScore.max),
      minDateFirstAvailable: generateDateForSegment(filters.listedAt.min),
      maxDateFirstAvailable: generateDateForSegment(filters.listedAt.max),
      includeKeywords: filters.includeKeywords.searchTerm,
      excludeKeywords: filters.excludeKeywords.searchTerm,
      excludeTopBrands,
      excludeUnavailableProducts: Boolean(
        filters.isUnavailable?.valuesArray?.length
      ),
      product: getProductForSegment(appType)
    })
  })
}

export const mapFiltersToApiParams = (filters, countryCategories) => {
  const {
    calculatedCategory: { valuesArray: categories },
    country: { valuesArray: country },
    excludeKeywords: { searchTerm: excludeKeywords },
    includeKeywords: { searchTerm: includeKeywords },
    sellerType: { valuesArray: sellerTypes },
    sort: { column, direction },
    tier: { valuesArray: tiers },
    isUnavailable: { valuesArray: isUnavailable },
    paginate: { pageSize, from },
    estRevenue,
    estimatedSales,
    listedAt,
    listingQualityScore,
    nReviews,
    nSellers,
    net,
    price,
    rank,
    rating,
    weight
  } = filters

  const reducedTiers = tiers.reduce((processedTiers, tier) => {
    if (tier === STANDARD_TIERS[0]) {
      processedTiers.push('Standard')
    } else if (tier === OVERSIZE_TIERS[0]) {
      processedTiers.push('Oversize')
    }
    return processedTiers
  }, [])

  const processedSellerTypes = [...sellerTypes]

  if (processedSellerTypes.includes('Merch')) {
    processedSellerTypes[processedSellerTypes.indexOf('Merch')] = 'FBM'
  }

  const mappedFilters = {
    country: country?.[0],
    column,
    direction,
    page_size: pageSize,
    from,
    include_keywords: includeKeywords,
    exclude_keywords: excludeKeywords,
    categories: getESFiltersValues(countryCategories, categories),
    seller_types: processedSellerTypes,
    tiers: reducedTiers,
    is_unavailable: isUnavailable.length > 0 ? isUnavailable[0] : null,
    start_date: listedAt.min || null,
    end_date: listedAt.max || null,
    estimated_revenue_min: Number(estRevenue.min) || null,
    estimated_revenue_max: Number(estRevenue.max) || null,
    estimated_sales_min: Number(estimatedSales.min) || null,
    estimated_sales_max: Number(estimatedSales.max) || null,
    listing_quality_score_min: Number(listingQualityScore.min) || null,
    listing_quality_score_max: Number(listingQualityScore.max) || null,
    number_reviews_min: Number(nReviews.min) || null,
    number_reviews_max: Number(nReviews.max) || null,
    number_sellers_min: Number(nSellers.min) || null,
    number_sellers_max: Number(nSellers.max) || null,
    net_min: Number(net.min) || null,
    net_max: Number(net.max) || null,
    price_min: Number(price.min) || null,
    price_max: Number(price.max) || null,
    rank_min: Number(rank.min) || null,
    rank_max: Number(rank.max) || null,
    rating_min: Number(rating.min) || null,
    rating_max: Number(rating.max) || null,
    weight_min: Number(weight.min) || null,
    weight_max: Number(weight.max) || null
  }

  return removeNullAndEmpty(mappedFilters)
}
