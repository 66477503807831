import i18n from 'services/i18n'
import { currencyIsDecimalized } from './currency'

const adjustedAmount = (amount, currencyCode) =>
  currencyIsDecimalized(currencyCode) ? amount : amount * 100

export const formatCurrency = (
  amount,
  currencyCode = 'USD',
  adjustAmount = true
) => {
  const incomingAmount = adjustAmount
    ? adjustedAmount(amount, currencyCode)
    : amount

  return Intl.NumberFormat(i18n.language, {
    style: 'currency',
    currency: currencyCode
  }).format(incomingAmount)
}

export const formatCurrencyWithCode = (amount, currencyCode) => {
  const formattedCurrency = formatCurrency(amount, currencyCode)
  const upperCurrencyCode = currencyCode.toUpperCase()

  // Regex pattern to match the currency code or its abbreviation
  const currencyCodePattern = new RegExp(
    `\\b${upperCurrencyCode}\\b|\\b${upperCurrencyCode.slice(0, 2)}\\b`,
    'i'
  )

  // Check if the formattedCurrency already contains the currency code, if so, return it
  if (currencyCodePattern.test(formattedCurrency)) {
    return formattedCurrency
  }

  return `${formattedCurrency} ${upperCurrencyCode}`
}

/**
 * Removes the cents from a currency string if they are zero such as .00 or ,00 depending on locale
 */
export const removeTrailingZeroes = formattedCurrency => {
  return formattedCurrency.replace(/([,.]00)(?=\s|$)/, '')
}
